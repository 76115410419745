@import url('https://fonts.googleapis.com/css2?family=Anybody:wght@100;400&family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&family=Macondo&family=Mochiy+Pop+P+One&family=Mukta:wght@200&family=Mulish&family=Ubuntu&display=swap');
/* font-family: 'Anybody', cursive;
font-family: 'League Spartan', sans-serif;
font-family: 'Macondo', cursive;
font-family: 'Mochiy Pop P One', sans-serif;
font-family: 'Mukta', sans-serif;
font-family: 'Mulish', sans-serif;
font-family: 'Ubuntu', sans-serif; */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif ;
}
.navbar{
  background-color: #fff !important ;
}
.navbar-brand{
  font-size: 1.7rem !important;
  color: #565387  !important;
}
.navbar a{
  font-size: 1.1rem;
  text-transform: capitalize;
}
/* .menu_active{
  font-weight: bold !important;
  border-bottom: 1px solid #565387 !important; */
/* } */
.navbar a:hover{
  color: #3498db !important;
}
.main{
  margin-top: 150px;
  height: 62vh;
}

#header h1{
  font-weight: 700;
  font-size: 48px;
  color: #111;
}
.brand-name{
  font-size: 2.6rem;
  color: #1b1b41;
  margin-left: 10px;
}
.para{
  font-size: 1.25rem;
}
.btn-get-started{
  background-color: #1b1b61;
  color: #fff;
  padding: 10px 15px;
  border-radius: 42px;
  font-weight: 500;
  text-decoration: none;
  font-size: 17px;
  letter-spacing: 1px;
  transition: .3s ease-in;
}
.btn-get-started:hover{
 background: none;
  color: #1b1b61;
  border: 1px solid #101012;
  }
.animated {
  animation: MoveUpDown 2s linear infinite;
  left: 0;
  bottom: 0;
  height: 500px;
}
.header-img{
  text-align: right;
}
.footer{
  display: flex;
 justify-content: center;
 flex-direction: column;
}
.footer .footerp{
  text-align: center;
  align-items: center;
  margin-bottom: 0px;
  font-size: 20px;
}
.footer .icont{
  display: flex;
  font-weight: 600;
}
.cont{
  text-align: center;
  font-weight: 600;
  margin-left: 20px;
}
.iconi{
  color: #08080a;
  margin-left: 20px;
}
.iconi i{
  margin: 0 4px;
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(-20px);
  }
  
  25% {
    transform: translateY(-30px);
  }

  50% {
    transform: translateY(-40px);
  }
}









@media(max-width:991px){
  .navbar-brand{
    font-size: 1.4rem !important;
  }
  .main {
    margin-top: 100px;
    height: 100%;
}
.footer .footerp {
  margin-top: 20px;
}
}




















body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
